/*
 * Custom Select jQuery Plugin Base
 */

$white: #fff !default;
$custom-select-block: "custom-select" !default;

.#{$custom-select-block} {
  position: relative;
  background-color: $white;

  &__option {
    overflow: hidden;
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 0;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-family: inherit;
    white-space: nowrap;
    text-align: left;
    text-overflow: ellipsis;
    cursor: pointer;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  &__input {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 0;
    border-width: 1px 0;
    border-style: solid;
    border-radius: 0;
    font-family: inherit;
  }

  &__dropdown {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    top: 100%;
    left: 0;
    background-color: $white;

    .#{$custom-select-block}--dropup & {
      top: auto;
      bottom: 100%;
    }
  }
}
